<template>
  <div>
    <div @click="onClickLeft" class="left">返回</div>
    <!-- <van-nav-bar title="宣传资料" left-arrow @click-left="onClickLeft" /> -->
    <!-- <div class="my-swipe2"> -->
    <van-swipe
      v-if="bannerList.length != 0"
      class="my-swipe"
      indicator-color="white"
      :show-indicators="false"
    >
      <van-swipe-item v-for="(item, index) in bannerList" :key="index">
        <!-- <img
            src="https://jxshjt.jxscloudmedia.com/uploads/png/20240514/0ebe2bf89bc802eccfc4c145a447382f.png"
          /> -->
        <img :src="item.url" />
      </van-swipe-item>
    </van-swipe>
    <!-- </div> -->
    <div
      class="img-div"
      v-if="bannerPath != '' && info.description == ''"
      style="overflow-y: hidden"
    >
      <!-- <img v-if="bannerPath != ''" class="img" src="https://jxshjt.jxscloudmedia.com/uploads/jpg/20240515/92393f4092f8cc2634f3a85f9b96ea5b.jpg" /> -->
      <img v-if="bannerPath != ''" class="img" :src="bannerPath" />
    </div>
    <div class="description" v-if="info.description != ''">
      <div class="bgColor"></div>
      <div class="detail_title">{{detail.title}}</div>
      <div v-html="detail.content"></div>
    </div>
  </div>
</template>
<script>
import { Toast, Swipe, SwipeItem, NavBar } from "vant";
import { article } from "@/api/apiData";
import { EasyScroller } from "easyscroller";
export default {
  components: {
    [Toast.name]: Toast,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [NavBar.name]: NavBar,
  },
  data() {
    return {
      bannerList: [],
      zoom: 1,
      bannerPath: "",
      id: "",
      scroller: null,
      info: "",
      detail: "",
    };
  },
  mounted() {
    let tit = this.$route.query.type;
    let id = this.$route.query.id;
    this.id = id;
    document.title = tit.replace("<br/>", "");
    article.lists({ channel_id: id }).then((res) => {
      if (res.code == 1) {
        this.info = res.data.list[0];
        // this.bannerPath = res.data.list[0].cover.replace(
        //   "https://192.168.1.152:8085",
        //   "https://jxshjt.jxscloudmedia.com"
        // );
        this.bannerPath = res.data.list[0].cover;
        if (res.data.list[0].description == "") {
          if (res.data.list[0].images.length == 0) {
            console.log(888)
             setTimeout(() => {
              this.touchS2();
            }, 1000);
            // this.touchS2();
          } else {
            // res.data.list[0].images.forEach((item) => {
            //   item.url = item.url.replace(
            //     "https://192.168.1.152:8085",
            //     "https://jxshjt.jxscloudmedia.com"
            //   );
            // });
            this.bannerList = res.data.list[0].images;
            setTimeout(() => {
              this.touchS3();
            }, 1000);
          }
        } else {
          article.detail({ article_id: res.data.list[0].id }).then((res2) => {
            this.detail = res2.data
          });
        }
      }
    });
  },
  methods: {
    touchS() {
      const content = document.querySelector(".img-div"); //获取需要放大缩小的盒子
      let firstDistance = 0; // 存放手指开始放上的时，两根手指之间的距离
      // 缩放事件的处理
      const getDistance = (start, stop) => {
        //计算两根手指之间的距离
        return Math.sqrt(
          Math.pow(Math.abs(start.x - stop.x), 2) +
            Math.pow(Math.abs(start.y - stop.y), 2)
        );
      };
      content.addEventListener("touchstart", function (event) {
        // 获取第一次触摸的点
        const touches = event.touches;
        if (touches.length > 1) {
          //判断是否是两指
          const events1 = touches[0];
          const events2 = touches[1];
          const one = {
            x: events1.pageX, //第一根手指的横坐标
            y: events1.pageY, //第一根手指的横坐标
          }; //第一根手指的横坐标
          const two = {
            x: events2.pageX, //第二根手指的横坐标
            y: events2.pageY, //第二根手指的横坐标
          };
          firstDistance = getDistance(one, two);
        }
        // event.preventDefault();
      });
      document.addEventListener("touchmove", (event) => {
        // event.preventDefault();
        const touches = event.touches;
        if (touches.length > 1) {
          const events1 = touches[0];
          const events2 = touches[1];
          const one = {
            x: events1.pageX, //第一根手指的横坐标
            y: events1.pageY, //第一根手指的横坐标
          }; //第一根手指的横坐标
          const two = {
            x: events2.pageX, //第二根手指的横坐标
            y: events2.pageY, //第二根手指的横坐标
          };
          const distance = getDistance(one, two);
          let zoom = distance / firstDistance;
          // content.style.transform = 'scale('+ zoom +')';
          content.style.zoom = this.zoom * zoom;
          //设置最大最小zomm
        }
      });
      document.addEventListener("touchend", (event) => {
        this.zoom = content.style.zoom;
      });
    },
    touchS2() {
      const ele = document.querySelector(".img-div"); //缩放盒子

      this.scroller = new EasyScroller(ele, {
        scrollingX: true,
        scrollingY: true,
        zooming: true,
        minZoom: 0.6, //最小缩放
        maxZoom: 5, //最大缩放
        zoomLevel: 0.5, //初始值缩放
        bouncing: false,
      });
    },
    touchS3() {
      console.log(888);
      const ele = document.querySelector(".my-swipe"); //缩放盒子
      console.log(ele);
      this.scroller = new EasyScroller(ele, {
        scrollingX: true,
        scrollingY: true,
        zooming: true,
        minZoom: 1, //最小缩放
        maxZoom: 5, //最大缩放
        zoomLevel: 0.5, //初始值缩放
        bouncing: false,
      });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
  },
  beforeDestroy() {
    if (this.scroller != null) {
      this.scroller.destroy(); //销毁
    }
  },
};
</script>
<style lang="less" scoped>
.left {
  position: fixed;
  left: 0;
  top: 10px;
  z-index: 2;
  background: #39a17d;
  color: white;
  font-size: 14px;
  padding: 2px 8px 2px 4px;
  border-radius: 0 10px 10px 0;
}
.my-swipe {
  img {
    width: 100%;
    // height: auto;
    height: 100vh;
    object-fit: contain;
    display: block;
  }
}
.img-div {
  // position: absolute;
  width: fit-content;
  height: 90vh;
  overflow-x: scroll;
  margin-top: 5vh;
  .img {
    //  width: 100%;
    height: 100%;

    object-fit: contain;
    display: block;
  }
}
.my-swipe2 {
  width: fit-content;
  height: 90vh;
}
img {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.description{
  margin: 20px;
  .detail_title{
    font-weight: bold;
    text-align: center;
    font-size: 18px;
    padding: 20px 0 10px;
  }
  .bgColor{
    position: fixed;
    width: 100%;
    height: 100%;
    background: white;
    top: 0;
    left: 0;
  }
}
</style>